@import 'styles/utils';

.maxWidth {
  max-width: 1410px;
  width: 100%;
}

.fullWidth {
  width: 100%;
}
