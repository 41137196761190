@import 'styles/utils';

.wrapper {
  height: 140px;
  // margin: 0 3%;
  color: $white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  row-gap: 20px;
  @include breakpoint('sm') {
    flex-direction: row;
    justify-content: space-between;
  }
}

.footerSmallLinkWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 10px;
  @include breakpoint('sm') {
    column-gap: 20px;
  }
  @include breakpoint('md') {
    column-gap: 30px;
  }
}

.footerSmallLink {
  color: inherit;
  text-decoration: none;
  &:hover {
    @include color-opacity;
  }
}

.socialMediaWrapper {
  display: flex;
  justify-content: center;
  column-gap: 15px;
  align-items: center;
  @include breakpoint('sm') {
    column-gap: 30px;
  }
}

.logo {
  height: 30px;
  width: 30px;
  &:hover {
    @include color-opacity;
  }
}
