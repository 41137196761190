$media-direction: min; // min / max — the result is @media ($min/max-width: 667px) {}

// Breakpoints

$xxs: 0;
$xs: 450;
$sm: 630;
$md: 770;
$lg: 850;
$xl: 1000;
$xxl: 1345;

$breakpoints: (
  'xxs': 0px,
  'xs': 450px,
  'sm': 630px,
  'md': 770px,
  'lg': 850px,
  'xl': 1000px,
  'xxl': 1345px,
);

:export {
  xxs: $xxs;
  xs: $xs;
  sm: $sm;
  md: $md;
  lg: $lg;
  xl: $xl;
  xxl: $xxl;
}
