.wrapper {
  position: relative;
  padding-left: 0;
  padding-right: 0;
  margin-right: 0;
  // pointer-events: none;
}
.imageBox {
  width: 100%;
  overflow: hidden;
}

.thumbnail {
  display: flex;
  flex-direction: row;
  justify-content: center;

  width: 100%;
  margin-top: 10px;
  overflow: hidden;
  cursor: pointer;
}
.inner {
  white-space: nowrap;
  width: 100%;
}
.imageContainer {
  width: 100%;
  height: 100%;

  transition: translate 300ms ease-in-out;
  flex: 1 0 auto;
  object-fit: cover;
}
.imageWrapper {
  display: flex;
}
.thumbnailImage {
  height: 90px;
  width: calc(25% - 7.5px);
  object-fit: cover;
  display: inline-flex;
  margin-right: 10px;
  transition: transform 300ms ease-in-out;
  &:last-of-type {
    margin-right: 0;
  }
}
