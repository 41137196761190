@import 'styles/utils';

.title {
  padding: 30px;
  background-color: $starcar-yellow;
  position: relative;
  display: inline-block;
  @include breakpoint('sm', 'max') {
    margin-bottom: 1rem;
  }
}

.title::before {
  content: '\f119';
  font-family: 'Font Awesome 5 Pro';
  font-weight: 900;
  padding-right: 20px;
}

.gridTitle {
  width: 49.5%;
  @include breakpoint('sm', 'max') {
    width: 100%;
    margin-bottom: 1rem;
  }
}

.button {
  font-size: 0.85em;
  text-align: center;
}

.errorContentContainer {
  width: 45%;
  @include breakpoint('md', 'max') {
    width: 100%;
  }
}

.errorSmallScreenDivider {
  display: none;
  @include breakpoint('md', 'max') {
    display: block;
  }
}

.errorContentItem {
  display: flex;
  justify-content: space-between;
  @include breakpoint('sm', 'max') {
    display: block;
    text-align: center;
  }
  align-items: center;
}

.errorImageContainer {
  max-width: 720px;
  margin: 0 auto;
}

.imageWrapper span {
  vertical-align: middle;
}

.wrapper {
  padding: 20px 30px;
  @include breakpoint('sm', 'max') {
    padding: 20px 10px;
  }
}

.container {
  max-width: 960px;
  margin: 0 auto;
}

.containerImage {
  max-width: 1600px;
  background-color: $starcar-yellow;
  border-top: 70px solid $starcar-yellow;
  border-bottom: 70px solid $starcar-yellow;
}

.containerAction {
  max-width: 1600px;
}

.boxAction {
  border-bottom: 50px solid $starcar-yellow;
  display: flex;
  justify-content: center;
}

.imageWrapper {
  @include breakpoint('md', 'max') {
    max-height: 50px;
  }
  @include breakpoint('sm', 'max') {
    max-height: 30px;
  }
}

.imageWrapper img {
  @include breakpoint('md', 'max') {
    max-height: 50px;
  }
  @include breakpoint('sm', 'max') {
    max-height: 30px;
  }
}

.retryButton {
  margin-bottom: 70px;
}

.errorPopupGrid {
  padding: 40px;
  @include breakpoint('md', 'max') {
    padding: 20px;
  }
  background-color: $starcar-yellow;
}

.errorPopupButton {
  @include breakpoint('md', 'max') {
    min-height: 20px;
  }
  min-height: 40px;
  @include font-size(16, 22.5);
  font-weight: 700;
  margin-top: 10px;
}
.errorBox {
  width: 1265px;
  padding: 4% 0% 4% 10%;

  background-color: $starcar-yellow;
  display: flex;
  align-items: center;
}
.iconBox {
  width: 50px;
  margin-right: 7%;
  @include breakpoint('md') {
    width: 70px;
  }
  @include breakpoint('xl') {
    width: 95px;
  }
}

.backgroundImageContainer {
  position: relative;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  .image {
    z-index: -1;
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 25%;
  }
  .content {
    position: relative;
    margin: 0 3%;
    z-index: 1;
    padding-top: 40px;
    .contentTitle {
      font-size: 32px;
      line-height: 36px;
      font-weight: 900;
      padding-top: 20px;
      width: 100%;
      @include breakpoint('sm') {
        width: 60%;
      }
      @include breakpoint('md') {
        width: 55%;
        font-size: 48px;
        line-height: 56px;
      }
      @include breakpoint('xxl') {
        width: 50%;
      }
    }
    .contentButton {
      margin-top: 20px;
    }
  }
}
