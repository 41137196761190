@import 'styles/utils';

.remoteStationInfo {
  background-color: $starcar-yellow;
  text-align: center;
  display: block;
  min-height: unset;
  min-width: unset;
  text-decoration: none;
  text-transform: none;
}

.remoteStationInfoHeadline.remoteStationInfoHeadline {
  font-weight: 700;
}

.remoteStationInfoTextCollapsed.remoteStationInfoTextCollapsed {
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.remoteStationInfoHeadlineBox.remoteStationInfoHeadlineBox {
  font-weight: 700;
  line-height: 24px;
  color: $grey-80;
}

.remoteStationInfoText.remoteStationInfoText {
  color: $grey-80;
}

.remoteStationInfoButton {
  width: 100%;
  height: 20px;
  margin-bottom: 15px;
  background-color: $starcar-yellow;
  text-align: center;
  &:hover {
    cursor: pointer;
  }
}
