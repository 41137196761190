@import 'styles/utils';
.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.loading:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: ellipsis steps(4, end) 900ms infinite;
  animation: ellipsis steps(4, end) 900ms infinite;
  content: "\2026";
  /* ascii code for the ellipsis character */
  width: 0px;
  position: absolute;
}
.image {
  width: 150px;
  height: auto;
  @include breakpoint('md') {
    width: 200px;
  }
}
.loading {
  text-align: center;
  color: $white
}
@keyframes ellipsis {
  to {
    width: 25px;
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 50px;
  }
}