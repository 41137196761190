@import 'styles/utils';

iframe#superchat-widget {
  z-index: 100 !important;
  bottom: 145px !important;
}
iframe#superchat-widget.open {
  z-index: 1400 !important;
  @media (max-width: 640px) {
    bottom: 0px !important;
  }
}
html {
  // overflow-x: hidden !important;
}
.adyen-checkout-sr-panel__msg {
  display: none;
  position: absolute;
  top: -1px;
}
body {
  margin: 0;

  overflow-x: hidden !important;
  // overflow-y: auto !important;  //Remove the !important if you want the scroll bar to disappear
  //  width: 100vw;
  @include breakpoint('md') {
    // overflow: inherit !important;
  }
  // overflow: visible !important;
}
#__next {
  height: 100svh;
}
