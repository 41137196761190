/* Font Sizes */

@import 'public/fonts/fonts.scss';

// Fonts
$font-family-default: var(--font-inter), sans-serif;;

:export {
  fontFamilyDefault: $font-family-default;
}

// Headlines
$font-size-h1: 48px;
$font-size-h2: 28px;
$font-size-h3: 22px;
$font-size-h4: 16px;

// Body
$font-size-default: 16px;
$font-size-body: 16px;
$font-size-small2: 11px;

:export {
  fontSizeDefault: $font-size-default;
  fontSizeBody: $font-size-body;
  fontSizeSmall2: $font-size-small2;
}

/* Line Height */
$line-height-normal: 1.167;
