@import 'styles/utils';
@import 'styles/_variables';
$cell-border-radius: 14px 12px;
$background-color-highlight: #fffbcc;
$background-color-highlight-circle: $starcar-yellow;
$background-color-default: #ffffff;
$text-color: #000000;
$text-color-disabled: #bbbbbb;
.dayCell {
  &.hidden {
    display: none;
  }
  &.isUnavailable {
    color: $text-color-disabled;
  }
  height: var(--calendar-cell-width);
  width: var(--calendar-cell-width);
  font-size: min(calc(var(--calendar-width) / 7 / 3), 20px);
  @include breakpoint('lg') {
    font-size: inherit;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  //   background-color: red;
  padding: $cell-border-radius;
  box-sizing: border-box;
  cursor: pointer;
  color: $text-color;
  outline: none;
  &.selected {
    &.isStart,
    &.isEnd {
      padding: 0;
      .innerCell {
        background-color: $background-color-highlight-circle;
        border-radius: 80px;
        padding: $cell-border-radius;
        height:  var(--calendar-cell-width);
        width:  var(--calendar-cell-width);
        display: flex;
        justify-content: center;
        align-items: center;
      }
      background: linear-gradient(
        90deg,
        $background-color-default 50%,
        $background-color-highlight 50%
      );
      &.isEnd {
        background: linear-gradient(
          90deg,
          $background-color-highlight 50%,
          $background-color-default 50%
        );
        &.isStart {
          background: $background-color-default;
        }
      }
      height: calc(100% - 20px);
    }
    background-color: $background-color-highlight;
  }
}

.timeField.timeField {
  font-weight: 700;
  font-size: 16px;
  color: $grey-80;
}

.divider {
  width: 1px;
  height: 20px;
  margin: 3px 8px 1px 7px;
  background-color: rgba(0, 0, 0, 0.1);
}
.makeClickableInBackdrop {
  @include breakpoint('lg') {
    z-index: 1300;
    input {
      z-index: 1300;
    }
  }
}
.timeAdornmentWrapper {
  display: flex;
  background-color: inherit;
  align-items: center;
  border: none;
  padding: 0;
  cursor: pointer;
  * {
    pointer-events: none;
  }
  @include breakpoint('lg') {
    .divider {
      margin-left: 0;
    }
    svg {
      margin-right: 5px;
    }
  }
}

.timeAdornmentWrapperAddPadding {
  padding-right: 7px;
}

.open.open {
  max-height: 500px;
  max-width: 1410px;
}
.calendarWrapper {
  max-height: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  transition: max-height 0.3s ease, max-width 0.3s ease;
}

.calendarInnerWrapper {
  padding: min(60px, 3vh) 0 30px;
  position: relative;
  max-width:var(--calendar-width);
  height: calc(100svh - 75px);
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  @include breakpoint('xs') {
    max-width: var(--calendar-width);
  }
  @include breakpoint('lg') {
    max-width: inherit;
    margin: inherit;
    display: inherit;
    height: inherit;
    padding: 0;
    @media screen and (max-width: 915px) {
      margin-left: -35px;
    }
    padding: 60px 0px;
  }
  @include breakpoint('xl') {
    padding: 60px 20px;
    margin-left: 0;
  }
  @include breakpoint('xxl') {
    padding: 60px 120px;
  }
}

.calendar {
  width: 315px;
  @include breakpoint('xs') {
    width: 344px;
  }
  @include breakpoint('lg') {
    width: 392px;
  }
}

.timeWrapper {
  padding: 0px 0;
  max-height: 450px;
  overflow: auto;
  @include breakpoint('lg') {
    padding: 0px 40px;
  }
  @include breakpoint('xxl') {
    padding: 0px 40px;
  }
}

.timeWrapperMobile {
  background-color: $white;
  padding: 0 10px 20px;
  position: relative;
  overflow: auto;
  @include breakpoint('lg') {
    max-height: 400px;
    height: auto;
  }
  height: 80%;
}



.timeFieldTitle.timeFieldTitle {
  font-weight: 700;
  line-height: 24px;
  font-size: 16px;
  color: $grey-80;
  margin-bottom: 10px;
}

.timeButton {
  padding: 0;
  border: none;
  background-color: inherit;
  width: 100%;
  cursor: pointer;
}

.nextMonthButtonMobile {
  display: flex;
  align-items: center;
  background-color: inherit;
  padding: 0;
  border: none;
  cursor: pointer;
  @include breakpoint('lg') {
    display: none;
  }
}
.modalHeader {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100px;
  // padding: 0 30px;
  top: 0;
  width: 100vw;
  
  left: 0;
  &.hidden.hidden {
    display: none;
  }
  @include breakpoint('lg') {
    width: 100%;
    // padding-left: 35px;
  }
  @include breakpoint('xl') {
    // padding-left: 35px;
  }
  @include breakpoint('xxl') {
    // padding-left: 40px;
  }
}
.timeSlotModalHeader {
  padding-bottom: 10px;
  .timeSelectMobileIcon {
    position: fixed;
    right:24px;
    @include breakpoint('lg') {
      right: -4px;
      position: absolute;
    }
    @include breakpoint('xxl') {
    }
    height: 24px;
    width: 24px;
    top: 15px;
    cursor: pointer;
  }
  &.isWidget {
    @include breakpoint('lg') {
      max-width: calc(100% - 50px);
      margin: 0 auto;
    }
    @include breakpoint('xl') {
      max-width: calc(100% - 50px);

    }
    @include breakpoint('xxl') {
      max-width: calc(100% - 60px);

    }
    
  }
  .headerDivider {
    width: 100%;
    height: 0;
      border-bottom: 1px solid $border-Grey;
  }
  .timeSlotInfoText {
    line-height: 24px; 
    margin-bottom: 10px; 
    width: 100%; 
  }

}
.closeButton {
  height: 24px;
  width: 24px;
  right: 54px;
  top: 15px;
  position: absolute;
  @include breakpoint('lg') {
   right: 0;

  }
  border: none;
  padding: 0;
  background-color: inherit;
  cursor: pointer;
}
.infoText {
  position: relative;
  line-height: 24px;
  left: 0;
  border-bottom: 1px solid $border-Grey;
  width: calc(100vw - 60px);
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding-top: 15px;
  p {
    line-height: 24px;
  }
  &.isWidget {
    @include breakpoint('xxs') {
      padding-left: 0;
    }
    @include breakpoint('lg') {
      width: 100%;
    }
  }
 
}
.mobileModalPaper {
  height: 100svh;
  border-radius: 0;
  padding: 0 30px;
  
  &.timeSlotsModalMobile {
    padding: 0;
    .timeWrapperMobile {
      padding: 0 26px 30px 30px;
      height: calc(100% - 75px);
    }
    .timeConfirmButton {
      .buttonDivider {
        border-top: 1px solid $border-Grey ;
        display:block;
        padding-bottom: 25px;
      }
      padding: 0 30px;
      padding-bottom: 25px;
      position: fixed;
      bottom: 0;
      left: 0;
      padding-top: 10px;
      background-color: white;
      // margin-bottom: -35px;
    }
  }
}
.submitButtonWrapper {
  justify-self: flex-end;
  justify-content: center;
  display: flex;
  position: absolute;
  width: calc(100% - 60px);
  left: 30px;
  top: calc(100svh - 95px);
  padding-top: min(25px, 2vh);
  border-top: 1px solid $border-Grey;
  @include breakpoint('lg') {
    display: none;
  }
}
.buttonCard {
  display: block;
  padding: 10px 0;
  border-radius: 2px;
  border: 1px solid #ededed;
  text-align: center;
  line-height: 24px;
  color: $grey-80;

  /* Hover and active states */
  @include breakpoint('lg') {

    &:hover,
    &:active {
      background-color: $starcar-yellow;
      border: 1px solid $starcar-yellow;
    }
  }
}

/* Modifier class for the selected state */
.timeSlot--active-bg {
  background-color: #ededed;
}
.timeSlot--active {
  background-color: $starcar-yellow;
  border: 1px solid $starcar-yellow;
}
